import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import deepCopy from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {withStyles} from "@material-ui/core/styles";
import {Container, TextField} from '@material-ui/core';
import MaterialTable from 'material-table';
import {forwardRef} from 'react';
import {withTranslation} from "react-i18next";
import localization from '../common/MaterialTableLocalization';

import {
    AddBox,
    ArrowUpward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    Remove,
    SaveAlt,
    Search,
    ViewColumn
} from "@material-ui/icons";
import {
    getLicenses,
    addLicense,
    editLicense,
    deleteLicense
} from "../../actions/esearch";
import {calculatePage} from "../../utils/tableUtils";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

const useStyles = theme => {
    return ({
        paper: {
            marginTop: theme.spacing(3),
            width: '100%',
            overflowX: 'auto',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 650,
        },
        button: {
            margin: theme.spacing(1),
        }
    })
};

class License extends Component {
    static propTypes = {
        licenses: PropTypes.array.isRequired,
        getLicenses: PropTypes.func.isRequired,
        addLicense: PropTypes.func.isRequired,
        editLicense: PropTypes.func.isRequired,
        deleteLicense: PropTypes.func.isRequired
    };

    state = {
        page: 0,
        pageSize: 20
    }

    handlePageChange = (newPage) => {
        this.setState({page: newPage}, () => {
            this.fetchLicenses();
        });
    }

    handlePageSizeChange = (newPageSize) => {
        this.setState({pageSize: newPageSize, page: 0}, () => {
            this.fetchLicenses();
        });
    }

    fetchLicenses = () => {
        const {page, pageSize} = this.state;
        this.props.getLicenses(page + 1, pageSize);
    }

    handleRowDelete = (licenseId) => {
        this.props.deleteLicense(licenseId);
        const {page, pageSize} = this.state;
        const newPage = calculatePage(page, pageSize, this.props.licenses.totalCount)
        this.handlePageChange(newPage)
    }

    componentDidMount() {
        this.fetchLicenses();
    };

    render() {
        const {classes, t} = this.props;
        return (
            <Container>
                <MaterialTable
                    icons={tableIcons}
                    title={t('LICENSES')}
                    columns={[
                        {title: t('LICENSE_NAME'), field: 'name', defaultSort: 'asc'},
                        {title: t('LICENSE_TYPE'), field: 'type'},
                        {title: t('LICENSE_VALUE'), field: 'value'},
                        {title: t('IS_COMPLETE'), field: 'is_complete', type: 'boolean', initialEditValue: false},
                        {
                            title: t('HANDLING_FEE'),
                            field: 'handling_fee',
                            type: 'number',
                            initialEditValue: 0,
                            editComponent: props => (
                                <TextField
                                    inputProps={{
                                        style: {textAlign: 'right'},
                                        type: 'number',
                                        min: 0,
                                        step: 0.01,
                                        disabled: !props.rowData.is_complete
                                    }}
                                    value={props.value}
                                    onChange={e => props.onChange(e.target.value)}
                                />
                            ),
                        },
                    ]}
                    options={{
                        sorting: true,
                        rowStyle: {
                            backgroundColor: 'palette.text.disabled'
                        },
                        headerStyle: {
                            backgroundColor: 'palette.text.disabled'
                        },
                        pageSize: this.state.pageSize,

                    }}
                    data={this.props.licenses.items}
                    page={this.state.page}
                    totalCount={this.props.licenses.totalCount}
                    onChangePage={(newPage) => this.handlePageChange(newPage)}
                    onChangeRowsPerPage={(newPageSize) => this.handlePageSizeChange(newPageSize)}
                    editable={{
                        onRowAdd: newData => new Promise((resolve, reject) => {
                            this.props.addLicense(newData);
                            resolve();
                        }),
                        onRowDelete: rowData => new Promise((resolve, reject) => {
                            this.handleRowDelete(rowData.id);
                            resolve();
                        }),
                        onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                            const changed = deepCopy(oldData);
                            delete changed.tableData;
                            if (!isEqual(newData, changed)) {
                                this.props.editLicense(newData, oldData);
                            }
                            resolve();
                        }),
                        isDeletable: rowData => !rowData.is_assigned_to_company,
                        isEditable: rowData => !rowData.is_assigned_to_company,
                    }}
                    localization={localization(t)}
                />
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    licenses: state.esearchReducer.licenses,
});

export default withStyles(useStyles)(connect(mapStateToProps, {
    getLicenses,
    addLicense,
    editLicense,
    deleteLicense
})(withTranslation(['common'])(License)));