import {
    ADD_TO_BASKET,
    EDIT_BASKET,
    REMOVE_FROM_BASKET,
    GET_COMPANY_ORDERS,
    GET_ORDER,
    UPDATE_ORDER,
    CLEAR_ORDERS,
    COPY_ORDER
} from "../actions/types";

const emptyOrder = {
    id: null,
    order_id: null,
    basket: [],
    basket_total: 0,
    order_created: null,
    order_released: null,
    cost_center: null,
    delivery_location: null,
    accounting_location: null,
    buyers_location: null,
    project_reference: null,
    customer_reference: null,
    note: null,
    department: null,
    full_name: null,
    incoterm: null,
    sent_date: null,
    status: null,
    integration_error_message: null,
    handling_fee: 0,
    supplier_costs: {
        transport_costs: 0,
        order_costs: 0
    },
    basket_total_with_handling_fee: 0,
    order_total: 0
}

const initialState = {
    company_orders: {
        items: [],
        totalCount: 0
    },
    current_order: JSON.parse(localStorage.getItem('current_order')) || emptyOrder
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_TO_BASKET:
            if(action.tenderRequestItemId){
                window.location.replace(`/#/cart/${action.payload.id}`)
            }
            return {
                ...state,
                current_order: action.payload
            };
        case REMOVE_FROM_BASKET:
            localStorage.setItem('current_order', JSON.stringify(action.payload))
            return {
                ...state,
                current_order: action.payload
            };
        case EDIT_BASKET:
            localStorage.setItem('current_order', JSON.stringify(action.payload))
            return {
                ...state,
                current_order: action.payload
            };
        case GET_COMPANY_ORDERS:
            return {
                ...state,
                company_orders: {
                    items: action.payload.results,
                    totalCount: action.payload.count
                }
            };
        case GET_ORDER:
            localStorage.setItem('current_order', JSON.stringify(action.payload))
            return {
                ...state,
                current_order: action.payload
            };
        case UPDATE_ORDER:
            localStorage.setItem('current_order', JSON.stringify(action.payload))
            return {
                ...state,
                current_order: action.payload,
                //company_orders: state.company_orders.filter(order => order.id !== action.payload.id).concat(action.payload)
            };
        case COPY_ORDER:
            localStorage.setItem('current_order', JSON.stringify(action.payload))
            return {
                ...state,
                current_order: action.payload,
                //TODO test on UI company_orders: state.company_orders.concat(action.payload)
            };
        case CLEAR_ORDERS:
            localStorage.removeItem('current_order')
            return {
                ...state,
                //company_orders: [],
                current_order: emptyOrder
            };
        default:
            return state;
    }
}
