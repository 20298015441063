import React, {useState} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Grid, MenuItem, TextField, Typography} from '@material-ui/core';
import {supportedCurrencies} from "../../../utils/constants";
import ModalDialog from "../../common/ModalDialog";


const useStyles = (theme) => {
    return ({
        paper: {
            marginTop: theme.spacing(3),
            paddingRight: theme.spacing(1),
            width: '100%',
            paddingBottom: theme.spacing(2),
            flexGrow: 1
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1.5),
            marginBottom: theme.spacing(0),
            width: '100%',
        },
    })
};


const TenderRequestItemOfferFormModal = ({
                                        isOpen,
                                        closeModal,
                                        currentOffer,
                                        tenderRequestItemId,
                                        createTenderRequestItemOffer,
                                        updateTenderRequestItemOffer,
                                        selectedSuppliersList,
                                        user,
                                        t,
                                        classes,
                                    }) => {

    const [formData, setFormData] = useState(currentOffer);

    const handleChange = event => {
        const {name, value} = event.target;

        setFormData({
            ...formData,
            ...{[name]: value}
        });
    };

    const handleSaveOffer = () => {
        const offer = {
            ...formData,
            tender_request_item: tenderRequestItemId
        }
        if (!offer.id) {
            createTenderRequestItemOffer(offer)
        } else {
            updateTenderRequestItemOffer(offer)
        }
        closeModal()
    }

    return (
        <ModalDialog
            isOpen={isOpen}
            closeModal={closeModal}
            onActionButtonClick={() => handleSaveOffer()}
            actionButtonText={t("SAVE")}
            closeButtonText={t("CLOSE")}
        >
            <Typography variant="h5" style={{textAlign: 'center'}}>
                {t('tender_module:TENDER_REQUEST_ITEM_OFFER')}
            </Typography>
            <Grid container spacing={1} className={classes.paper}>
                {user.is_esearch_admin && (formData.supplier_name ? (
                            <TextField
                                className={classes.textField}
                                fullWidth
                                margin="dense"
                                label={t('SUPPLIER_NAME')}
                                name="supplier"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    readOnly: true,
                                    required: true
                                }}
                                value={formData.supplier_name}
                            />
                        )
                        :
                        (<TextField className={classes.textField}
                                    select
                                    fullWidth
                                    margin="dense"
                                    label={t('SUPPLIER_NAME')}
                                    name="supplier"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                        required: true
                                    }}
                                    SelectProps={{
                                        displayEmpty: true,
                                        value: formData.supplier_name,
                                        onChange: handleChange
                                    }}
                        >
                            {selectedSuppliersList.map((item) => (
                                <MenuItem key={item.id} value={item.id}>{item.company_name}</MenuItem>
                            ))}
                        </TextField>)
                )}
                <TextField className={classes.textField}
                           label={t('ARTICLE_NUMBER')}
                           value={formData.article_number}
                           variant="outlined"
                           margin="dense"
                           name="article_number"
                           onChange={handleChange}
                           inputProps={{
                               min: 1
                           }}
                           InputLabelProps={{
                               shrink: true,
                               required: true
                           }}
                />
                <TextField className={classes.textField}
                           label={t('NET_PRICE_PER_UNIT')}
                           value={formData.net_price}
                           margin="dense"
                           name="net_price"
                           onChange={handleChange}
                           variant="outlined"
                           type="number"
                           inputProps={{
                               min: 1
                           }}
                           InputLabelProps={{
                               shrink: true,
                               required: true
                           }}
                />
                <TextField className={classes.textField}
                           select
                           fullWidth
                           margin="dense"
                           label={t('CURRENCY')}
                           name="currency"
                           variant="outlined"
                           InputLabelProps={{
                               shrink: true,
                               required: true
                           }}
                           SelectProps={{
                               displayEmpty: true,
                               value: formData.currency || supportedCurrencies[0],
                               onChange: handleChange
                           }}
                >
                    {supportedCurrencies.map((item) => (
                        <MenuItem key={item} value={item}>{item}</MenuItem>
                    ))}
                </TextField>
                <TextField className={classes.textField}
                           margin="dense"
                           label={t('MIN_ORDER_QUANTITY')}
                           value={formData.min_quantity}
                           name="min_quantity"
                           variant="outlined"
                           type="number"
                           onChange={handleChange}
                           inputProps={{
                               min: 1
                           }}
                           InputLabelProps={{
                               shrink: true,
                               required: true
                           }}
                >
                </TextField>
                <TextField className={classes.textField}
                           label={t('DELIVERY_TIME')}
                           value={formData.delivery_days}
                           variant="outlined"
                           margin="dense"
                           name="delivery_days"
                           type="number"
                           onChange={handleChange}
                           inputProps={{
                               min: 1
                           }}
                           InputLabelProps={{
                               shrink: true,
                               required: true
                           }}
                />
                <TextField className={classes.textField}
                           label={t('tender_module:ADDITIONAL_COSTS')}
                           value={formData.additional_costs}
                           name="additional_costs"
                           margin="dense"
                           onChange={handleChange}
                           variant="outlined"
                           type="number"
                           inputProps={{
                               min: 1
                           }}
                           InputLabelProps={{
                               shrink: true,
                               required: true
                           }}
                />
                <TextField className={classes.textField}
                           label={t('tender_module:ADDITIONAL_INFO')}
                           value={formData.additional_info}
                           name="additional_info"
                           margin="dense"
                           onChange={handleChange}
                           variant="outlined"
                           InputLabelProps={{
                               shrink: true,
                               required: false
                           }}
                />
            </Grid>
        </ModalDialog>
    )
}

export default withStyles(useStyles)(TenderRequestItemOfferFormModal)