import {
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAIL,
    EDIT_USER,
    GET_USERS,
    DELETE_USER,
    CLEAR_USERS
} from "../actions/types";


const initialState = {
    users: {
        items: [],
        totalCount: 0
    }
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_USERS: {
            return {
                users: {
                    items: [],
                    totalCount: 0
                }
            }
        }
        case GET_USERS:
            return {
                ...state,
                users: {
                    items: action.payload.results,
                    totalCount: action.payload.totalCount
                }
            };
        case REGISTER_USER_SUCCESS:
            return {
                ...state,
                users: {
                    items: [...state.users.items, action.payload],
                    totalCount: state.users.totalCount + 1
                }
            };
        case DELETE_USER:
            return {
                ...state,
                users: {
                    items: state.users.items.filter(user => user.id !== action.payload),
                    totalCount: state.users.totalCount - 1
                }
            };
        case EDIT_USER:
            return {
                ...state,
                users: {
                    ...state.users,
                    items: state.users.items.filter(user => user !== action.oldData).concat(action.newData),
                }
            };
        case REGISTER_USER_FAIL:
        default:
            return state
    }
}