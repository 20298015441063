import {createMessage, returnErrors} from "./messages";
import {tokenConfig} from "./auth";
import axiosInterceptor from "../interceptor";

import {
    ADD_BRANCH_OFFICE,
    ADD_COMPANY,
    ADD_COMPANY_ROLE,
    ADD_COST_CENTER,
    ADD_ELEMENTS_MAPPING,
    ADD_ENGINE,
    ADD_LICENSE,
    ADD_SEARCH_PARAMS,
    ADD_USER_ROLE,
    CLEAR_DIRECT_SEARCH,
    CLEAR_SEARCH,
    DELETE_BRANCH_OFFICE,
    DELETE_COST_CENTER,
    DELETE_ELEMENTS_MAPPING,
    DELETE_SEARCH_PARAMS,
    DIRECT_SEARCH,
    EDIT_BRANCH_OFFICE,
    EDIT_COMPANY,
    EDIT_COMPANY_ROLE,
    EDIT_COST_CENTER,
    EDIT_ELEMENTS_MAPPING,
    EDIT_ENGINE,
    EDIT_LICENSE,
    EDIT_SEARCH_PARAMS,
    EDIT_USER_ROLE,
    EXECUTE_SEARCH,
    GET_ALL_USER_ROLES,
    GET_COMPANIES,
    GET_COMPANY_ROLES,
    GET_COUNTRIES,
    GET_ENGINES,
    GET_ALL_ENGINES,
    GET_LICENSES,
    GET_OCI,
    GET_OCI_ELEMENTS,
    GET_SEARCH_ELEMENTS,
    GET_USER_ROLES,
    OCI_SEARCH,
    SEARCHING,
    TRADE_ITEMS_2BA,
    GET_ALL_COMPANY_ROLES,
    GET_ALL_LICENSES,
    GET_ALL_OCI,
    GET_ALL_COMPANIES,
    GET_ALL_OCI_ELEMENTS,
    GET_ALL_SEARCH_ELEMENTS
} from "./types";

// SEND SEARCH STRING TO BACKEND
export const executeSearch = (item) => (dispatch, getState) => {
    dispatch({
        type: CLEAR_SEARCH
    });
    dispatch({
        type: SEARCHING,
        payload: item.searchString
    });
    axiosInterceptor
        .post('/api/search/', item, tokenConfig(getState))
        .then(res => {
            if (res.data.hasOwnProperty('OCI')) {
                dispatch({
                    type: OCI_SEARCH,
                    payload: res.data
                })
            } else {
                dispatch({
                    type: EXECUTE_SEARCH,
                    payload: res.data
                })
            }
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// DIRECT SEARCH EXECUTE
export const directSearch = (item) => (dispatch, getState) => {
    dispatch({
        type: DIRECT_SEARCH,
        payload: item
    })
};

export const clearDirectSearch = () => (dispatch) => {
    dispatch({
        type: CLEAR_DIRECT_SEARCH
    })
};

// CLEAR SEARCH
export const clearSearch = () => (dispatch, getState) => {
    dispatch({
        type: CLEAR_SEARCH
    })
};

// GET TRADE ITEMS 2ba
export const getTradeItems2ba = (item, channel_name) => (dispatch, getState) => {
    axiosInterceptor
        .get(`/api/search/?GTIN=${item.GTIN}&Id=${item.Id}&Description=${item.Description}&ChannelName=${channel_name}`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: TRADE_ITEMS_2BA,
                payload: res.data,
                id: item.Id
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// GET DISCOUNT INFO
export const getDiscountInfo = (item) => (dispatch, getState) => {
    axiosInterceptor
        .get(`/api/search/?DiscountInfo=true&gln=${item.gln}&trade_item_id=${item.trade_item_id}`, tokenConfig(getState))
        .then(res => {
            return res.data
        })
};

// GET BACKGROUND SEARCH ENGINES
export const getEngines = (page, pageSize) => (dispatch, getState) => {
    const params = {page, page_size: pageSize}

    axiosInterceptor
        .get('/api/background_search/', {
            params,
            ...tokenConfig(getState)
        })
        .then(res => {
            dispatch({
                type: GET_ENGINES,
                payload: res.data
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const getAllEngines = () => (dispatch, getState) => {
    const params = {show_all: true}

    axiosInterceptor
        .get('/api/background_search/', {
            params,
            ...tokenConfig(getState)
        })
        .then(res => {
            dispatch({
                type: GET_ALL_ENGINES,
                payload: res.data
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// DELETE BACKGROUND SEARCH ENGINES
export const deleteEngine = engineId => (dispatch, getState) => {
    axiosInterceptor
        .delete(`/api/background_search/${engineId}/`, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({deleteEngine: 'Search Engine Deleted'}));
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// ADD BACKGROUND SEARCH ENGINE
export const addEngine = item => (dispatch, getState) => {
    axiosInterceptor
        .post('/api/background_search/', item, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({addEsearch: 'Search Engine Added'}));
            dispatch({
                type: ADD_ENGINE,
                payload: res.data
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

//EDIT BACKGROUND SEARCH ENGINE
export const editEngine = (newData, oldData) => (dispatch, getState) => {
    axiosInterceptor
        .patch(`/api/background_search/${oldData.id}/`, newData, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({editEngine: 'Search Engine Edited'}));
            dispatch({
                type: EDIT_ENGINE,
                newData: res.data,
                oldData: oldData
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// GET COMPANIES
export const getCompanies = (page, pageSize) => (dispatch, getState) => {
    const params = {page, page_size: pageSize}

    axiosInterceptor
        .get('/api/company/',
            {
                params,
                ...tokenConfig(getState)
            })
        .then(res => dispatch({
            type: GET_COMPANIES,
            payload: res.data
        }))
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const getAllCompanies = () => (dispatch, getState) => {
    const params = {show_all: true}

    axiosInterceptor
        .get('/api/company/',
            {
                params,
                ...tokenConfig(getState)
            })
        .then(res => dispatch({
            type: GET_ALL_COMPANIES,
            payload: res.data
        }))
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// ADD COMPANY
export const addCompany = item => (dispatch, getState) => {
    axiosInterceptor
        .post('/api/company/', item, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({addCompany: 'Company Added'}));
            dispatch({
                type: ADD_COMPANY,
                payload: res.data
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// EDIT COMPANY
export const editCompany = (newData, oldData) => (dispatch, getState) => {
    axiosInterceptor
        .patch(`/api/company/${oldData.id}/`, newData, tokenConfig((getState)))
        .then(res => {
            dispatch(createMessage({editCompany: 'Company Edited'}));
            dispatch({
                type: EDIT_COMPANY,
                newData: res.data,
                oldData: oldData
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// DELETE COMPANY (does not really delete it but marks it as is_deleted)

export const deleteCompany = companyId => (dispatch, getState) => {
    axiosInterceptor
        .delete(`api/company/${companyId}/`, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({deleteCompany: 'Company Deleted'}));
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// GET OCI
export const getOCI = (page, pageSize) => (dispatch, getState) => {
    const params = {page, page_size: pageSize}

    axiosInterceptor
        .get('/api/oci/', {
            params,
            ...tokenConfig(getState)
        })
        .then(res => dispatch({
            type: GET_OCI,
            payload: res.data
        }))
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const getAllOCI = () => (dispatch, getState) => {
    const params = {show_all: true}

    axiosInterceptor
        .get('/api/oci/', {
            params,
            ...tokenConfig(getState)
        })
        .then(res => dispatch({
            type: GET_ALL_OCI,
            payload: res.data
        }))
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// GET OCI ELEMENTS
export const getOCIElements = (page, pageSize) => (dispatch, getState) => {
    const params = {page, page_size: pageSize}

    axiosInterceptor
        .get('/api/oci_elements/', {
            params,
            ...tokenConfig(getState)
        })
        .then(res => dispatch({
            type: GET_OCI_ELEMENTS,
            payload: res.data
        }))
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const getAllOCIElements = () => (dispatch, getState) => {
    const params = {show_all: true}

    axiosInterceptor
        .get('/api/oci_elements/', {
            params,
            ...tokenConfig(getState)
        })
        .then(res => dispatch({
            type: GET_ALL_OCI_ELEMENTS,
            payload: res.data
        }))
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// GET SEARCH ELEMENTS (elements of GUI for search engine mapped to OCI elements)
export const getSearchElements = (page, pageSize) => (dispatch, getState) => {
    const params = {page, page_size: pageSize}

    axiosInterceptor
        .get('/api/search_elements/', {
            params,
            ...tokenConfig(getState)
        })
        .then(res => dispatch({
            type: GET_SEARCH_ELEMENTS,
            payload: res.data
        }))
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const getAllSearchElements = () => (dispatch, getState) => {
    const params = {show_all: true}

    axiosInterceptor
        .get('/api/search_elements/', {
            params,
            ...tokenConfig(getState)
        })
        .then(res => dispatch({
            type: GET_ALL_SEARCH_ELEMENTS,
            payload: res.data
        }))
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};


// ADD ELEMENTS MAPPING
export const addElementsMapping = item => (dispatch, getState) => {
    axiosInterceptor
        .post('/api/elements_mapping/', item, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({addElementsMapping: 'Mapping Added'}));
            dispatch({
                type: ADD_ELEMENTS_MAPPING,
                payload: res.data
            });
            //TODO
            dispatch(getEngines())
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// DELETE ELEMENTS MAPPING
export const deleteElementsMapping = item => (dispatch, getState) => {
    axiosInterceptor
        .delete(`/api/elements_mapping/${item.id}/`, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({deleteElementsMapping: 'Mapping Deleted'}));
            dispatch({
                type: DELETE_ELEMENTS_MAPPING,
                payload: item.id
            });
            //TODO
            dispatch(getEngines())
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// EDIT ELEMENTS MAPPING
export const editElementsMapping = (newData, oldData) => (dispatch, getState) => {
    axiosInterceptor
        .patch(`/api/elements_mapping/${oldData.id}/`, newData, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({editElementsMapping: 'Mapping Edited'}));
            dispatch({
                type: EDIT_ELEMENTS_MAPPING,
                newData: res.data,
                oldData: oldData
            });
            // TODO: remove extra call and update redux state Issue #9
            dispatch(getEngines());
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// ADD SEARCH PARAMS
export const addSearchParams = item => (dispatch, getState) => {
    axiosInterceptor
        .post('/api/search_params/', item, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({addSearchParams: 'Search Parameter Added'}));
            dispatch({
                type: ADD_SEARCH_PARAMS,
                payload: res.data
            });
            //TODO
            dispatch(getEngines())
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// DELETE SEARCH PARAMS
export const deleteSearchParams = item => (dispatch, getState) => {
    axiosInterceptor
        .delete(`/api/search_params/${item.id}/`, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({deleteSearchParams: 'Search Parameter Deleted'}));
            dispatch({
                type: DELETE_SEARCH_PARAMS,
                payload: item.id
            });
            //TODO
            dispatch(getEngines())
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// EDIT SEARCH PARAMS
export const editSearchParams = (newData, oldData) => (dispatch, getState) => {
    axiosInterceptor
        .patch(`/api/search_params/${oldData.id}/`, newData, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({editSearchParams: 'Search Parameter Edited'}));
            dispatch({
                type: EDIT_SEARCH_PARAMS,
                newData: res.data,
                oldData: oldData
            });
            // TODO: remove extra call and update redux state Issue #9
            dispatch(getEngines());
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// GET COMPANY ROLES
export const getCompanyRoles = (page, pageSize) => (dispatch, getState) => {
    const params = {page, page_size: pageSize}

    axiosInterceptor
        .get('/api/company_roles/', {
            params,
            ...tokenConfig(getState)
        })
        .then(res => dispatch({
            type: GET_COMPANY_ROLES,
            payload: res.data
        }))
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const getAllCompanyRoles = () => (dispatch, getState) => {
    const params = {show_all: true}

    axiosInterceptor
        .get('/api/company_roles/', {
            params,
            ...tokenConfig(getState)
        })
        .then(res => dispatch({
            type: GET_ALL_COMPANY_ROLES,
            payload: res.data
        }))
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// ADD COMPANY ROLE
export const addCompanyRole = item => (dispatch, getState) => {
    axiosInterceptor
        .post('/api/company_roles/', item, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({addRole: 'Company Role Added'}));
            dispatch({
                type: ADD_COMPANY_ROLE,
                payload: res.data
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// EDIT COMPANY ROLE
export const editCompanyRole = (newData, oldData) => (dispatch, getState) => {
    axiosInterceptor
        .patch(`/api/company_roles/${oldData.id}/`, newData, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({editRole: 'Company Role Edited'}));
            dispatch({
                type: EDIT_COMPANY_ROLE,
                newData: res.data,
                oldData: oldData
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// DELETE COMPANY ROLE
export const deleteCompanyRole = roleId => (dispatch, getState) => {
    axiosInterceptor
        .delete(`/api/company_roles/${roleId}/`, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({deleteRole: 'Company Role Deleted'}));
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// GET USER ROLES
export const getUserRoles = (page, pageSize) => (dispatch, getState) => {
    const params = {page, page_size: pageSize}

    axiosInterceptor
        .get('/api/user_roles/', {
            params,
            ...tokenConfig(getState)
        })
        .then(res => dispatch({
            type: GET_USER_ROLES,
            payload: res.data
        }))
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const getAllUserRoles = () => (dispatch, getState) => {
    const params = {show_all: true}

    axiosInterceptor
        .get('/api/user_roles/', {
            params,
            ...tokenConfig(getState)
        })
        .then(res => dispatch({
            type: GET_ALL_USER_ROLES,
            payload: res.data
        }))
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// ADD USER ROLE
export const addUserRole = item => (dispatch, getState) => {
    axiosInterceptor
        .post('/api/user_roles/', item, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({addRole: 'User Role Added'}));
            dispatch({
                type: ADD_USER_ROLE,
                payload: res.data
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// EDIT USER ROLE
export const editUserRole = (newData, oldData) => (dispatch, getState) => {
    axiosInterceptor
        .patch(`/api/user_roles/${oldData.id}/`, newData, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({editRole: 'User Role Edited'}));
            dispatch({
                type: EDIT_USER_ROLE,
                newData: res.data,
                oldData: oldData
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// DELETE USER ROLE
export const deleteUserRole = roleId => (dispatch, getState) => {
    axiosInterceptor
        .delete(`/api/user_roles/${roleId}/`, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({deleteRole: 'User Role Deleted'}));
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// GET LICENSES
export const getLicenses = (page, pageSize) => (dispatch, getState) => {
    const params = {page, page_size: pageSize}

    axiosInterceptor
        .get('/api/licenses/', {
            params,
            ...tokenConfig(getState)
        })
        .then(res => dispatch({
            type: GET_LICENSES,
            payload: res.data
        }))
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const getAllLicenses = () => (dispatch, getState) => {
    const params = {show_all: true}

    axiosInterceptor
        .get('/api/licenses/', {
            params,
            ...tokenConfig(getState)
        })
        .then(res => dispatch({
            type: GET_ALL_LICENSES,
            payload: res.data
        }))
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// ADD LICENSE
export const addLicense = item => (dispatch, getState) => {
    axiosInterceptor
        .post('/api/licenses/', item, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({addRole: 'License Added'}));
            dispatch({
                type: ADD_LICENSE,
                payload: res.data
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// EDIT LICENSE
export const editLicense = (newData, oldData) => (dispatch, getState) => {
    axiosInterceptor
        .patch(`/api/licenses/${oldData.id}/`, newData, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({editRole: 'License Edited'}));
            dispatch({
                type: EDIT_LICENSE,
                newData: res.data,
                oldData: oldData
            });
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// DELETE LICENSE
export const deleteLicense = licenseId => (dispatch, getState) => {
    axiosInterceptor
        .delete(`/api/licenses/${licenseId}/`, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({deleteLicense: 'License Deleted'}));
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// ADD BRANCH OFFICE
export const addBranchOffice = item => (dispatch, getState) => {
    axiosInterceptor
        .post('/api/branch_office/', item, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({addRole: 'Branch Office Added'}));
            dispatch({
                type: ADD_BRANCH_OFFICE,
                payload: res.data
            });
            // TODO
            dispatch(getCompanies())
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// EDIT BRANCH OFFICE
export const editBranchOffice = (newData, oldData) => (dispatch, getState) => {
    axiosInterceptor
        .patch(`/api/branch_office/${oldData.id}/`, newData, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({editRole: 'Branch Office Edited'}));
            dispatch({
                type: EDIT_BRANCH_OFFICE,
                newData: res.data,
                oldData: oldData
            });
            // TODO
            dispatch(getCompanies())
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// DELETE BRANCH OFFICE
export const deleteBranchOffice = item => (dispatch, getState) => {
    axiosInterceptor
        .delete(`/api/branch_office/${item.id}/`, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({deleteLicense: 'Branch Office Deleted'}));
            dispatch({
                type: DELETE_BRANCH_OFFICE,
                payload: item.id
            });
            // TODO
            dispatch(getCompanies())
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// ADD COST CENTER
export const addCostCenter = item => (dispatch, getState) => {
    axiosInterceptor
        .post('/api/cost_centers/', item, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({addCostCenter: 'Cost Center Added'}));
            dispatch({
                type: ADD_COST_CENTER,
                payload: res.data
            });
            // TODO
            dispatch(getCompanies())
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// EDIT COST CENTER
export const editCostCenter = (newData, oldData) => (dispatch, getState) => {
    axiosInterceptor
        .patch(`/api/cost_centers/${oldData.id}/`, newData, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({editCostCenter: 'Cost Center Edited'}));
            dispatch({
                type: EDIT_COST_CENTER,
                newData: res.data,
                oldData: oldData
            });
            // TODO
            dispatch(getCompanies())
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// DELETE COST CENTER
export const deleteCostCenter = item => (dispatch, getState) => {
    axiosInterceptor
        .delete(`/api/cost_centers/${item.id}/`, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({deleteConstCenter: 'Cost Center Deleted'}));
            dispatch({
                type: DELETE_COST_CENTER,
                payload: item.id
            });
            // TODO
            dispatch(getCompanies())
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

// GET COUNTRIES
export const getCountries = () => (dispatch, getState) => {
    axiosInterceptor
        .get('/api/countries', tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_COUNTRIES,
                payload: res.data
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

