import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import deepCopy from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {withStyles} from "@material-ui/core/styles";
import {Container} from '@material-ui/core';
import MaterialTable from 'material-table';
import {forwardRef} from 'react';
import {withTranslation} from "react-i18next";
import localization from '../common/MaterialTableLocalization';

import {
    AddBox,
    ArrowUpward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    Remove,
    SaveAlt,
    Search,
    ViewColumn
} from "@material-ui/icons";
import {
    getUserRoles,
    addUserRole,
    editUserRole,
    deleteUserRole
} from "../../actions/esearch";
import {calculatePage} from "../../utils/tableUtils";
import {PAGE_SIZE} from "../../utils/constants";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

const useStyles = theme => {
    return ({
        paper: {
            marginTop: theme.spacing(3),
            width: '100%',
            overflowX: 'auto',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 650,
        },
        button: {
            margin: theme.spacing(1),
        }
    })
};

class UserRole extends Component {
    static propTypes = {
        user_roles: PropTypes.array.isRequired,
        getUserRoles: PropTypes.func.isRequired,
        addUserRole: PropTypes.func.isRequired,
        editUserRole: PropTypes.func.isRequired,
        deleteUserRole: PropTypes.func.isRequired
    };

    state = {
        page: 0,
        pageSize: PAGE_SIZE
    }

    handlePageChange = (newPage) => {
        this.setState({ page: newPage }, () => {
            this.fetchUserRoles();
        });
    }

    handlePageSizeChange = (newPageSize) => {
        this.setState({ pageSize: newPageSize, page: 0 }, () => {
            this.fetchUserRoles();
        });
    }

    fetchUserRoles = () => {
        const { page, pageSize } = this.state;
        this.props.getUserRoles(page + 1, pageSize);
    }

    handleRowDelete = (companyId) => {
        this.props.deleteUserRole(companyId);
        const {page, pageSize} = this.state;
        const newPage = calculatePage(page, pageSize, this.props.user_roles.totalCount)
        this.handlePageChange(newPage)
    }

    componentDidMount() {
        this.fetchUserRoles();
    };

    render () {
        const {classes, t} = this.props;
        return (
            <Container>
                <MaterialTable
                    icons={tableIcons}
                    title={t('USER_ROLES')}
                    columns={[
                        {title: t('ROLE'), field: 'role', defaultSort: 'asc', initialEditValue: ''},
                    ]}
                    options={{
                        sorting: true,
                        rowStyle: {
                            backgroundColor: 'palette.text.disabled'
                        },
                        headerStyle: {
                            backgroundColor: 'palette.text.disabled'
                        },
                        pageSize: this.state.pageSize,
                    }}
                    data={this.props.user_roles.items}
                    page={this.state.page}
                    totalCount={this.props.user_roles.totalCount}
                    onChangePage={(newPage) => this.handlePageChange(newPage)}
                    onChangeRowsPerPage={(newPageSize) => this.handlePageSizeChange(newPageSize)}
                    editable={{
                        onRowAdd: newData => new Promise((resolve, reject) => {
                            this.props.addUserRole(newData);
                            resolve();
                        }),
                        onRowDelete: rowData => new Promise((resolve, reject) => {
                            this.handleRowDelete(rowData.id);
                            resolve();
                        }),
                        onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                            const changed = deepCopy(oldData);
                            delete changed.tableData;
                            if (!isEqual(newData, changed)) {
                                this.props.editUserRole(newData, oldData);
                            }
                            resolve();
                        }),
                    }}
                    localization={localization(t)}
                />
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    user_roles: state.esearchReducer.user_roles,
});

export default withStyles(useStyles)(connect(mapStateToProps, {
    getUserRoles,
    addUserRole,
    editUserRole,
    deleteUserRole
})(withTranslation(['common'])(UserRole)));