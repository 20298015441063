import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Button} from '@material-ui/core';
import Modal from "react-modal";


const useStyles = theme => {
    return ({
        modalContent: {
            width: '50%',
            height: '70%',
            maxHeight: '700px',
            margin: 'auto',
            background: theme.palette.background.default,
            padding: '20px',
            border: '1px solid rgb(204, 204, 204)',
            position: 'absolute',
            inset: '40px'
        },
        paper: {
            marginTop: theme.spacing(3),
            paddingRight: theme.spacing(1),
            width: '100%',
            paddingBottom: theme.spacing(2),
            flexGrow: 1
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1.5),
            marginBottom: theme.spacing(0),
            width: '100%',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            marginTop: '20px',
        },
        button: {
            marginLeft: '10px',
        },
    })
};

const styles = {
    modal: {
        overlay: {
            zIndex: 10,
            backgroundColor: 'inherit'
        }
    },
};

const ModalDialog = ({
                         isOpen,
                         closeModal,
                         onActionButtonClick,
                         actionButtonText,
                         closeButtonText,
                         children,
                         classes,
                     }) => {
    const onClose = () => {
        closeModal()
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={styles.modal}
            className={{
                base: `${classes.modalContent} ReactModal__Content`,
            }}
        >
            {children}
            <div className={classes.buttonContainer}>
                <Button
                    variant="contained"
                    color="default"
                    onClick={onClose}
                    className={classes.button}
                >
                    {closeButtonText}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onActionButtonClick}
                    className={classes.button}
                >
                    {actionButtonText}
                </Button>
            </div>
        </Modal>
    )
}

export default withStyles(useStyles)(ModalDialog)