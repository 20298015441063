export const ESEARCH_SUPPLIER_GLN = "8719329065004"
export const supportedCurrencies = ['EUR', 'USD'];

export const supportedCurrencySymbols = {
    EUR: '€',
    USD: '$'
};

export const SupplierCostType = {
    TRANSPORT: 'TRANSPORT',
    ORDER: 'ORDER',
}

export const SupplierCostFormat = {
    COST_PER_AMOUNT: "COST_PER_AMOUNT",
    FIXED_FEE_UNDER_VALUE: "FIXED_FEE_UNDER_VALUE",
    COST_PER_ORDER: "COST_PER_ORDER",
    PERCENTAGE_OF_AMOUNT: "PERCENTAGE_OF_AMOUNT"
}

export const PAGE_SIZE = 20;