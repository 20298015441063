import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import Login from "../accounts/Login";
import PrivateRoute from "../common/PrivateRoute";
import Dashboard from "../esearch/Dashboard";
import AdminRoute from "../common/AdminRoute";
import Register from "../accounts/Register";
import Users from "../accounts/Users";
import RequestResetPassword from "../accounts/RequestResetPassword";
import ResetPassword from "../accounts/ResetPassword";
import Company from "../esearch/Company";
import Role from "../esearch/Role";
import License from "../esearch/License";
import ShoppingCart from "../esearch/ShoppingCart";
import Orders from "../esearch/Orders";
import Suppliers2ba from "../esearch/supplier/Suppliers2ba";
import TenderRequests from "../esearch/tender-module/TenderRequestsTable";
import TenderRequest from "../esearch/tender-module/TenderRequest";

class RegisteredRoutes extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/request_reset_password" component={RequestResetPassword}/>
                <Route path="/password-reset/:token" component={ResetPassword}/>
                <PrivateRoute exact path="/" component={Dashboard}/>
                <PrivateRoute exact path="/cart/:id" component={ShoppingCart}/>
                <PrivateRoute exact path="/orders" component={Orders}/>
                <PrivateRoute exact path="/tender-requests" component={TenderRequests}/>
                <PrivateRoute exact path="/tender-request" component={TenderRequest}/>
                <PrivateRoute exact path="/tender-request/:id" component={TenderRequest}/>
                <AdminRoute exact path="/register" component={Register}/>
                <AdminRoute exact path="/users" component={Users}/>
                <AdminRoute exact path="/companies" component={Company}/>
                <AdminRoute exact path="/roles" component={Role}/>
                <AdminRoute exact path="/licenses" component={License}/>
                <AdminRoute exact path="/suppliers2ba" component={Suppliers2ba}/>

            </Switch>
        );
    }
}

export default RegisteredRoutes;