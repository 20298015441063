import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Box, Button, Container} from '@material-ui/core';
import {withTranslation} from "react-i18next";
import TenderRequestForm from "./TenderRequestForm";
import TenderRequestItemsTable from "./TenderRequestItemsTable";
import TenderRequestItemsForm from "./TenderRequestItemForm";
import {withStyles} from "@material-ui/core/styles";
import {
    clearTenderRequestItemData,
    createTenderRequest,
    createTenderRequestItem,
    downloadTenderRequestItemImages,
    getTenderRequestsById,
    sendTenderRequest,
    reopenTenderRequest,
    setTenderRequestItemData,
    updateTenderRequest,
    updateTenderRequestItem,
    uploadTenderRequestItemImage,
    createTenderRequestItemsFromCsv,
} from "../../../actions/tenderRequest";
import {useParams} from "react-router-dom";
import {emptyTenderRequestItem, TenderRequestStatus} from "../../../reducers/tenderRequest";
import {UploadFileButton} from "../common/UploadFileButton";
import {getAllSuppliers2ba} from "../../../actions/supplier";

const useStyles = () => {
    return ({
        buttonContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            margin: "10px 10px 10px 0"
        },
        csvFileUploadButton: {
            marginRight: "10px",
        },
    })
};

const TenderRequest = ({
                           currentTenderRequest,
                           createTenderRequest,
                           updateTenderRequest,
                           getTenderRequestsById,
                           sendTenderRequest,
                           reopenTenderRequest,
                           currentTenderRequestItem,
                           createTenderRequestItem,
                           updateTenderRequestItem,
                           setTenderRequestItemData,
                           clearTenderRequestItemData,
                           uploadTenderRequestItemImage,
                           downloadTenderRequestItemImages,
                           createTenderRequestItemsFromCsv,
                           user,
                           getAllSuppliers2ba,
                           suppliersList,
                           classes,
                           t,
                       }) => {
    const {id} = useParams();
    const [displayTenderRequestForm, setDisplayTenderRequestForm] = useState(true)
    const [tenderRequestFormData, setTenderRequestFormData] = useState(currentTenderRequest);
    const [tenderRequestItemFormData, setTenderRequestItemFormData] = useState(currentTenderRequestItem);

    useEffect(() => {
        getAllSuppliers2ba();
        if (id) {
            getTenderRequestsById(id)
        }
    }, [id]);

    useEffect(() => {
        setTenderRequestItemFormData(currentTenderRequestItem)
    }, [currentTenderRequestItem]);

    useEffect(() => {
        if (!currentTenderRequest.id) {
            currentTenderRequest = {
                ...currentTenderRequest, department: user.department,
                delivery_location: user.branch_offices.find(item => item.id === user.branch_office).city
            }
        }
        setTenderRequestFormData(currentTenderRequest)

        if(currentTenderRequest.status !== TenderRequestStatus.DRAFT && !displayTenderRequestForm){
            setDisplayTenderRequestForm(true)
        }

    }, [currentTenderRequest])

    const handleSaveTenderRequest = () => {
        if (!tenderRequestFormData.id) {
            createTenderRequest(tenderRequestFormData)
        } else {
            updateTenderRequest(tenderRequestFormData)
        }
    }

    const handleCreateTenderRequestItem = () => {
        createTenderRequestItem(tenderRequestFormData.id, tenderRequestItemFormData)
    }

    const handleAddTenderRequestItemsClick = () => {
        setTenderRequestItemFormData(emptyTenderRequestItem)
        setDisplayTenderRequestForm(false)
    }

    const updateTenderRequestFormData = (newData) => {
        setTenderRequestFormData({
            ...tenderRequestFormData,
            ...newData
        });
    };

    const updateTenderRequestItemFormData = (newData) => {
        setTenderRequestItemFormData({
            ...tenderRequestItemFormData,
            ...newData
        });
    };

    const selectTenderRequestItem = (tenderRequestItem) => {
        setTenderRequestItemData(tenderRequestItem)
        setDisplayTenderRequestForm(false)
    }

    const handleCsvUpload = (csvFile) => {
        createTenderRequestItemsFromCsv(currentTenderRequest.id, csvFile)
    }

    const isTenderRequestUpdatable = (!user.is_supplier && (!tenderRequestFormData.id || tenderRequestFormData.status === TenderRequestStatus.DRAFT)) ||
        (tenderRequestFormData.status === TenderRequestStatus.PENDING && user.is_esearch_admin)

    return (
        <Container maxWidth={false}>
            {displayTenderRequestForm &&
                <div>
                    <TenderRequestForm t={t}
                                       tenderRequestFormData={tenderRequestFormData}
                                       saveTenderRequest={handleSaveTenderRequest}
                                       reopenTenderRequest = {reopenTenderRequest}
                                       updateTenderRequestFormData={updateTenderRequestFormData}
                                       suppliersList={suppliersList}
                                       user={user}
                                       isUpdatable={isTenderRequestUpdatable}
                    />

                    {tenderRequestFormData.status && tenderRequestFormData.status === TenderRequestStatus.DRAFT &&
                        <Box className={classes.buttonContainer}>
                            <UploadFileButton onFileUpload={handleCsvUpload} className={classes.csvFileUploadButton}
                                              fileType=".csv" isDisabled={!currentTenderRequest.id}
                                              buttonLabel={t("CREATE_ITEMS_FROM_CSV_FILE")}/>
                            <Button variant="contained"
                                    color="primary"
                                    onClick={() => handleAddTenderRequestItemsClick()}
                                    disabled={!currentTenderRequest.id}
                            >
                                {t('tender_module:ADD_TENDER_REQUEST_ITEMS')}
                            </Button>
                        </Box>
                    }
                </div>
            }
            {!displayTenderRequestForm &&
                <TenderRequestItemsForm t={t}
                                        returnToHandleRequest={() => setDisplayTenderRequestForm(true)}
                                        createTenderRequestItem={handleCreateTenderRequestItem}
                                        tenderRequestItemFormData={tenderRequestItemFormData}
                                        updateTenderRequestItem={updateTenderRequestItem}
                                        tenderRequestFormData={tenderRequestFormData}
                                        sendTenderRequest={sendTenderRequest}
                                        updateTenderRequestItemFormData={updateTenderRequestItemFormData}
                                        status={tenderRequestFormData.status || TenderRequestStatus.DRAFT}
                                        clearForm={clearTenderRequestItemData}
                                        uploadTenderRequestItemImage={uploadTenderRequestItemImage}
                                        downloadTenderRequestItemImages={downloadTenderRequestItemImages}
                                        numberOfItems={currentTenderRequest.items.length}
                />
            }
            <TenderRequestItemsTable t={t} items={currentTenderRequest.items}
                                     status={tenderRequestFormData.status}
                                     user={user}
                                     selectTenderRequestItem={selectTenderRequestItem}
                                     downloadTenderRequestItemImages={downloadTenderRequestItemImages}
                                     selectedSuppliersList={currentTenderRequest.selected_suppliers}
            />
        </Container>
    )
}

const mapStateToProps = state => ({
    currentTenderRequest: state.tenderRequestReducer.currentTenderRequest,
    currentTenderRequestItem: state.tenderRequestReducer.currentTenderRequestItem,
    user: state.authReducer.user,
    suppliersList: state.supplierReducer.suppliers2ba.items
});

export default withStyles(useStyles)(connect(mapStateToProps, {
    createTenderRequest,
    updateTenderRequest,
    getTenderRequestsById,
    sendTenderRequest,
    reopenTenderRequest,
    getAllSuppliers2ba,
    createTenderRequestItem,
    updateTenderRequestItem,
    setTenderRequestItemData,
    clearTenderRequestItemData,
    uploadTenderRequestItemImage,
    downloadTenderRequestItemImages,
    createTenderRequestItemsFromCsv,
})(withTranslation(['common', 'tender_module'])(TenderRequest)));