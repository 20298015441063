import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Paper,
    Checkbox,
    Tooltip,
} from '@material-ui/core';
import {OpenInNew, LocalShipping, ShoppingCart} from '@material-ui/icons';
import {addToBasket, createBasket} from "../../actions/orders";
import {SpinnerComponent} from "../common/SpinnerComponent";
import {AddToCartToolbar} from "./common/AddToCartToolbar";
import {PAGE_SIZE, SupplierCostFormat, SupplierCostType} from "../../utils/constants";
import {formatPrice} from "../../utils/orderUtils";


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
    const {classes, order, orderBy, onRequestSort, t, oci, user_data} = props;

    let headCells = [
        {id: 'Image', numeric: false, disablePadding: true, label: t('IMAGE')},
        {id: 'TradeItemId', numeric: false, disablePadding: true, label: t('ARTICLE_NUMBER')},
        {id: 'SupplierName', numeric: false, disablePadding: true, label: t('SUPPLIER')},
        {id: 'Description', numeric: false, disablePadding: true, label: t('PRODUCT_DESCRIPTION')},
        {id: 'GrossPriceInOrderUnit', numeric: false, disablePadding: true, label: t('PRICE_PER_PACKAGE_BRUTO')},
        {id: 'ExtraCosts', numeric: false, disablePadding: true, label: t('EXTRA_COSTS')},
        {id: 'MinimumOrderQuantity', numeric: false, disablePadding: true, label: t('MIN_ORDER_QUANTITY')},
        {id: 'OrderUnitMeasureUnitCode', numeric: false, disablePadding: true, label: t('UNIT_OF_MEASURE')},
        {id: 'DeliveryTime', numeric: false, disablePadding: true, label: t('DELIVERY_TIME')},
        {id: 'Deeplink', numeric: false, disablePadding: true, label: t('ARTICLE_DEEP_LINK')}
    ];

    if (user_data && user_data.parent_company && ['EDI', 'Purchase'].includes(user_data.parent_company.license)) {
        headCells = [
            {id: 'Image', numeric: false, disablePadding: true, label: t('IMAGE')},
            {id: 'TradeItemId', numeric: false, disablePadding: true, label: t('ARTICLE_NUMBER')},
            {id: 'SupplierName', numeric: false, disablePadding: true, label: t('SUPPLIER')},
            {id: 'Description', numeric: false, disablePadding: true, label: t('PRODUCT_DESCRIPTION')},
            {id: 'NetPriceInOrderUnit', numeric: false, disablePadding: true, label: t('PRICE_PER_PACKAGE')},
            {id: 'GrossPriceInOrderUnit', numeric: false, disablePadding: true, label: t('PRICE_PER_PACKAGE_BRUTO')},
            {id: 'ExtraCosts', numeric: false, disablePadding: true, label: t('EXTRA_COSTS')},
            {id: 'MinimumOrderQuantity', numeric: false, disablePadding: true, label: t('MIN_ORDER_QUANTITY')},
            {id: 'OrderUnitMeasureUnitCode', numeric: false, disablePadding: true, label: t('UNIT_OF_MEASURE')},
            {id: 'PricePerUnit', numeric: false, disablePadding: true, label: t('PRICE_PER_UNIT')},
            {id: 'DeliveryTime', numeric: false, disablePadding: true, label: t('DELIVERY_TIME')},
            {id: 'Deeplink', numeric: false, disablePadding: true, label: t('ARTICLE_DEEP_LINK')}
        ];
    }

    if (!oci) {
        headCells = headCells.filter(header => header.id !== 'Description')
    }

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell/>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    oci: PropTypes.bool.isRequired,
    user_data: PropTypes.object.isRequired
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    deepLink: {
        display: 'table-cell',
        textDecoration: 'none',
        color: 'inherit'
    },
    checkBox: {
        textDecoration: 'none',
        color: 'primary'
    }
}));


function EnhancedTable(props) {
    const {data, translation, addToBasket, createBasket, current_order, user_data, fallbackImage, oci} = props;
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selectedItem, setSelectedItem] = React.useState({});
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(PAGE_SIZE);

    const replaceBrokenImage = event => {
        event.target.src = fallbackImage
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleClick = (event, row) => {
        if (!row.NetPriceWithHandlingFee || !row.PricePerUnit) {
            return null
        }
        setSelectedItem(row);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getSupplierCostIcons = (supplierCosts, t) => {
        const transportCosts = findSupplierCostByType(supplierCosts, SupplierCostType.TRANSPORT)
        const orderCosts = findSupplierCostByType(supplierCosts, SupplierCostType.ORDER)
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {transportCosts &&
                    <Tooltip
                        title={`${t("TRANSPORT_COSTS")}: ${getSupplierCostsTooltipMessage(transportCosts, t)}`}
                        arrow>
                        <LocalShipping style={{marginLeft: "15px"}}/>
                    </Tooltip>
                }
                {orderCosts &&
                    <Tooltip title={`${t("ORDER_COSTS")}: ${getSupplierCostsTooltipMessage(orderCosts, t)}`} arrow>
                        <ShoppingCart style={{marginLeft: "15px"}}/>
                    </Tooltip>}
            </div>
        );
    }

    const findSupplierCostByType = (supplierCosts, type) => {
        return supplierCosts.find(cost => cost.cost_type === type) || null;
    }

    const getSupplierCostsTooltipMessage = (supplierCost, t) => {
        switch (supplierCost.cost_format) {
            case SupplierCostFormat.COST_PER_AMOUNT:
                return t('tooltip.costPerAmount', {
                    amount: supplierCost.cost_value,
                    limit: supplierCost.cost_limit
                });
            case SupplierCostFormat.FIXED_FEE_UNDER_VALUE:
                return `${supplierCost.cost_value} < ${supplierCost.cost_limit}`;
            case SupplierCostFormat.COST_PER_ORDER:
                return t('tooltip.costPerOrder', {amount: supplierCost.cost_value});
            case SupplierCostFormat.PERCENTAGE_OF_AMOUNT:
                return `${supplierCost.cost_value}%`
            default:
                return '';
        }
    };


    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                {selectedItem.Id && user_data && user_data.parent_company && ['EDI'].includes(user_data.parent_company.license) &&
                    <AddToCartToolbar
                        currentOrder={current_order}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        user={user_data}
                        t={translation}
                        createBasket={createBasket}
                        addToBasket={addToBasket}
                    />}
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            t={translation}
                            oci={oci}
                            user_data={user_data}
                        />
                        <TableBody>
                            {stableSort(data, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = row.Id === selectedItem.Id;
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={index}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox" onClick={event => handleClick(event, row)}>
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{'aria-labelledby': labelId}}
                                                    disabled={!row.NetPriceWithHandlingFee || !row.PricePerUnit}
                                                />
                                            </TableCell>
                                            <TableCell padding="none" onClick={event => handleClick(event, row)}>
                                                <img
                                                    src={row.ImageURL}
                                                    style={{width: 40, borderRadius: '10%'}}
                                                    onError={replaceBrokenImage}
                                                />
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" padding="none"
                                                       onClick={event => handleClick(event, row)}>
                                                {row.TradeItemId}
                                            </TableCell>
                                            <TableCell padding="none" onClick={event => handleClick(event, row)}>
                                                {row.SupplierName}
                                            </TableCell>
                                            {
                                                oci ? <TableCell padding="none"
                                                                 onClick={event => handleClick(event, row)}>
                                                    {row.Description}
                                                </TableCell> : ''
                                            }
                                            {
                                                user_data && user_data.parent_company && ['EDI', 'Purchase'].includes(user_data.parent_company.license) &&
                                                <TableCell padding="none" onClick={event => handleClick(event, row)}>
                                                    {(row.NetPriceWithHandlingFee) ? (
                                                        formatPrice(row.CurrencyCode, row.NetPriceWithHandlingFee)
                                                    ) : (
                                                        row.NetPriceWithHandlingFee === 0 ? translation('UNAVAILABLE') :
                                                            <SpinnerComponent/>
                                                    )}
                                                </TableCell>
                                            }
                                            <TableCell padding="none" onClick={event => handleClick(event, row)}>
                                                {formatPrice(row.CurrencyCode, row.GrossPriceInOrderUnit ? row.GrossPriceInOrderUnit : 0)}
                                            </TableCell>
                                            <TableCell padding="none" onClick={event => handleClick(event, row)}>
                                                {getSupplierCostIcons(row.supplier_costs, translation)}
                                            </TableCell>
                                            <TableCell padding="none" onClick={event => handleClick(event, row)}>
                                                {row.MinimumOrderQuantity}
                                            </TableCell>
                                            <TableCell padding="none" onClick={event => handleClick(event, row)}>
                                                {row.OrderUnitMeasureUnitCode}
                                            </TableCell>
                                            {
                                                user_data && user_data.parent_company && ['EDI', 'Purchase'].includes(user_data.parent_company.license) &&
                                                <TableCell padding="none" onClick={event => handleClick(event, row)}>
                                                    {(row.PricePerUnit) ? (
                                                        formatPrice(row.CurrencyCode, row.PricePerUnit)
                                                    ) : (
                                                        row.PricePerUnit === 0 ? translation('UNAVAILABLE') :
                                                            <SpinnerComponent/>
                                                    )}
                                                </TableCell>
                                            }
                                            <TableCell padding="none" onClick={event => handleClick(event, row)}>
                                                {row.DeliveryTime ?? 1}
                                            </TableCell>
                                            <TableCell padding="none">{row.Deeplink ? <a className={classes.deepLink}
                                                                                         href={row.Deeplink}
                                                                                         target="_blank"><OpenInNew/></a> : ''}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    backIconButtonText={translation('table.pagination.previousTooltip')}
                    nextIconButtonText={translation('table.pagination.nextTooltip')}
                    labelRowsPerPage={translation('table.pagination.labelRowsPerPage')}
                    labelDisplayedRows={({from, to, count}) =>
                        <span>{from}-{to === -1 ? count : to} {translation('table.pagination.labelDisplayedRows')} {count}</span>}
                />
            </Paper>
        </div>
    );
}

const mapStateToProps = state => ({
    current_order: state.ordersReducer.current_order,
    user_data: state.authReducer.user,
    fallbackImage: state.authReducer.fallbackImage,
});

export default connect(mapStateToProps, {addToBasket, createBasket})(EnhancedTable);