import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {integrateOrder} from "../../actions/orders";
import {TextField, Typography, Box} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import {withTranslation} from "react-i18next";
import ModalDialog from "../common/ModalDialog";

const useStyles = () => {
    return ({
        container: {
            width: '100%',
            height: '90%'
        },
        textField: {
            margin: '20px 0',
            height: '100%',
        },
        inputRoot: {
            height: '90%',
        }
    })
};


const styles = {
    textArea: {
        height: '100%',
        overflowX: 'auto',
        whiteSpace: 'nowrap'
    },
};

const OrderIntegrationFormModal = ({isOpen, closeModal, t, integrateOrder, classes}) => {
    const [xmlData, setXmlData] = useState('');

    const onChange = event => {
        setXmlData(event.target.value);
    };
    const onClose = () => {
        setXmlData('')
        closeModal()
    }

    return (
        <ModalDialog
            isOpen={isOpen}
            closeModal={onClose}
            onActionButtonClick={() => integrateOrder(xmlData)}
            actionButtonText={t("SEND")}
            closeButtonText={t("CLOSE")}
        >
            <Box className={classes.container}>
                <Typography variant="h5" style={{textAlign: 'center'}}>
                    {t('ORDER_INTEGRATION')}
                </Typography>
                    <TextField
                        label={t('SCSN_XML_DATA')}
                        multiline
                        fullWidth
                        value={xmlData}
                        onChange={onChange}
                        variant="outlined"
                        required
                        inputProps={{style: styles.textArea}}
                        InputProps={{classes: {root: classes.inputRoot}}}
                        className={classes.textField}
                    />
            </Box>
        </ModalDialog>
    );
};

OrderIntegrationFormModal.propTypes = {
    integrateOrder: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

export default withStyles(useStyles)(connect(mapStateToProps, {integrateOrder})
(withTranslation(['common'])(OrderIntegrationFormModal)));
