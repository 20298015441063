import React, {Component, forwardRef} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import deepCopy from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {withStyles} from "@material-ui/core/styles";
import {Container, TextField} from '@material-ui/core';
import MaterialTable from 'material-table';
import {withTranslation} from "react-i18next";
import localization from '../common/MaterialTableLocalization';

import {
    AccountCircle,
    AddBox,
    ArrowUpward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    PieChart,
    Remove,
    SaveAlt,
    Search,
    ViewColumn
} from "@material-ui/icons";
import {
    addBranchOffice,
    addCompany,
    addCostCenter,
    deleteBranchOffice,
    deleteCompany,
    deleteCostCenter,
    editBranchOffice,
    editCompany,
    editCostCenter,
    getCompanies,
    getAllCompanyRoles,
    getCountries,
    getAllEngines,
    getAllLicenses,
    getAllUserRoles
} from "../../actions/esearch";
import {deleteUser, editUser, registerUser} from "../../actions/users";

import SelectRole from "./SelectRole";
import SelectLicense from "./SelectLicense";
import SelectEngine from "../accounts/SelectEngine";
import SelectCountry from "./SelectCountry";
import {calculatePage} from "../../utils/tableUtils";
import {PAGE_SIZE} from "../../utils/constants";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

const useStyles = theme => {
    return ({
        paper: {
            marginTop: theme.spacing(3),
            width: '100%',
            overflowX: 'auto',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 650,
            maxWidth: 'xs'
        },
        button: {
            margin: theme.spacing(1),
        }
    })
};

class Company extends Component {
    static propTypes = {
        companies: PropTypes.array.isRequired,
        company_roles: PropTypes.array.isRequired,
        user_roles: PropTypes.array.isRequired,
        licenses: PropTypes.array.isRequired,
        esearch: PropTypes.array.isRequired,
        getCompanies: PropTypes.func.isRequired,
        addCompany: PropTypes.func.isRequired,
        editCompany: PropTypes.func.isRequired,
        deleteCompany: PropTypes.func.isRequired,
        getAllCompanyRoles: PropTypes.func.isRequired,
        getAllUserRoles: PropTypes.func.isRequired,
        getAllLicenses: PropTypes.func.isRequired,
        addBranchOffice: PropTypes.func.isRequired,
        editBranchOffice: PropTypes.func.isRequired,
        deleteBranchOffice: PropTypes.func.isRequired,
        getAllEngines: PropTypes.func.isRequired,
        registerUser: PropTypes.func.isRequired,
        editUser: PropTypes.func.isRequired,
        deleteUser: PropTypes.func.isRequired,
        addCostCenter: PropTypes.func.isRequired,
        editCostCenter: PropTypes.func.isRequired,
        deleteCostCenter: PropTypes.func.isRequired,
        getCountries: PropTypes.func.isRequired,
        countries: PropTypes.array.isRequired,
    };

    state = {
        page: 0,
        pageSize: PAGE_SIZE,
        searchQuery: ''
    }

    handlePageChange = (newPage) => {
        this.setState({page: newPage}, () => {
            this.fetchCompanies();
        });
    }

    handlePageSizeChange = (newPageSize) => {
        this.setState({pageSize: newPageSize, page: 0}, () => {
            this.fetchCompanies();
        });
    }

    handleSearchChange = (searchQuery) => {
        this.setState({searchQuery, page: 0}, () => {
            this.fetchCompanies();
        });
    }

    fetchCompanies = () => {
        const {page, pageSize, searchQuery} = this.state;
        this.props.getCompanies(page + 1, pageSize, searchQuery);
    }

    handleRowDelete = (companyId) => {
        this.props.deleteCompany(companyId);
        const {page, pageSize} = this.state;
        const newPage = calculatePage(page, pageSize, this.props.companies.totalCount)
        this.handlePageChange(newPage)
    }

    componentDidMount() {
        this.fetchCompanies();
        this.props.getAllCompanyRoles();
        this.props.getAllUserRoles();
        this.props.getAllLicenses();
        this.props.getAllEngines();
        this.props.getCountries();
    };

    onLicenceChange = (selectedValue, props) => {
        props.rowData.handling_fee = this.props.licenses.items.find(licence => licence.name === selectedValue).handling_fee
        props.onChange(selectedValue)
    }

    render() {
        const {classes, t} = this.props;
        return (
            <Container maxWidth={false}>
                <MaterialTable
                    icons={tableIcons}
                    title={t('company:TITLE')}
                    columns={[
                        {title: t('company:NAME'), field: 'name', defaultSort: 'asc', initialEditValue: ''},
                        {title: t('company:IS_ACTIVE'), field: 'is_active', type: 'boolean', initialEditValue: true,},
                        {
                            title: t('company:COMPANY_ROLE'),
                            field: 'company_role',
                            initialEditValue: '',
                            cellStyle: {whiteSpace: 'nowrap'},
                            render: rowData => rowData.company_role,
                            editComponent: props => (
                                <SelectRole value={props.value} onChange={props.onChange}
                                            roles={this.props.company_roles.items}/>
                            )
                        },
                        {
                            title: t('company:LICENSE'),
                            field: 'license',
                            initialEditValue: '',
                            cellStyle: {whiteSpace: 'nowrap'},
                            render: rowData => rowData.license,
                            editComponent: props => (
                                <SelectLicense value={props.value} onChange={(selectedValue) =>
                                    this.onLicenceChange(selectedValue, props)}
                                               licenses={this.props.licenses.items}/>
                            )
                        },
                        {
                            title: t('SEARCH_ENGINES'), field: 'search_engines',
                            render: rowData => rowData.search_engines.join(', '),
                            editComponent: props => (
                                <SelectEngine value={props.value} onChange={props.onChange}
                                              eSearch={this.props.esearch.items}/>
                            ),
                            initialEditValue: [],
                            cellStyle: {whiteSpace: 'nowrap'}
                        },
                        {
                            title: t('HANDLING_FEE'),
                            field: 'handling_fee',
                            initialEditValue: 0,
                            type: 'numeric',
                            editComponent: props => {
                                return (
                                    <TextField
                                        inputProps={{
                                            style: {textAlign: 'right'},
                                            type: 'number',
                                            min: 0,
                                            step: 0.01,
                                            disabled: !this.props.licenses.items.find(licence => licence.name === props.rowData.license)?.is_complete
                                        }}
                                        value={props.value}
                                        onChange={e => props.onChange(e.target.value)}
                                    />
                                )
                            },
                            cellStyle: {whiteSpace: 'nowrap'}
                        }
                    ]}
                    options={{
                        sorting: true,
                        rowStyle: {
                            backgroundColor: 'palette.text.disabled'
                        },
                        headerStyle: {
                            backgroundColor: 'palette.text.disabled'
                        },
                        pageSize: this.state.pageSize,
                    }}
                    data={this.props.companies.items}
                    page={this.state.page}
                    totalCount={this.props.companies.totalCount}
                    onChangePage={this.handlePageChange}
                    onChangeRowsPerPage={this.handlePageSizeChange}
                    onSearchChange={this.handleSearchChange}
                    editable={{
                        onRowAdd: newData => new Promise((resolve, reject) => {
                            this.props.addCompany(newData);
                            resolve();
                        }),
                        onRowDelete: rowData => new Promise((resolve, reject) => {
                            this.handleRowDelete(rowData.id);
                            resolve();
                        }),
                        onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                            const changed = deepCopy(oldData);
                            delete changed.tableData;
                            if (!isEqual(newData, changed)) {
                                this.props.editCompany(newData, oldData);
                            }
                            resolve();
                        }),
                    }}
                    localization={localization(t)}
                    detailPanel={[
                        {
                            tooltip: t('company:BRANCH_OFFICES'),
                            render: rowData => {
                                return (
                                    <Container maxWidth='lg'>
                                        <MaterialTable
                                            icons={tableIcons}
                                            title={t('company:BRANCH_OFFICES')}
                                            columns={[
                                                {
                                                    title: t('company:BRANCH_NAME'),
                                                    field: 'branch_name',
                                                    defaultSort: 'asc'
                                                },
                                                {
                                                    title: 'GLN Code',
                                                    field: 'gln',
                                                },
                                                {
                                                    title: t('company:IS_BILLING_ADDRESS'),
                                                    field: 'is_billing_address',
                                                    type: 'boolean'
                                                },
                                                {
                                                    title: t('company:ADDRESS'),
                                                    field: 'address',
                                                },
                                                {
                                                    title: t('company:BUILDING_NUMBER'),
                                                    field: 'building_number',
                                                },
                                                {
                                                    title: t('company:CITY'),
                                                    field: 'city',
                                                },
                                                {
                                                    title: t('company:POST_CODE'),
                                                    field: 'postcode',
                                                },
                                                {
                                                    title: t('company:COUNTRY'),
                                                    field: 'country',
                                                    initialEditValue: 'NL',
                                                    cellStyle: {whiteSpace: 'nowrap'},
                                                    render: rowData => rowData.country,
                                                    editComponent: props => (
                                                        <SelectCountry value={props.value} onChange={props.onChange}
                                                                       countries={this.props.countries}/>
                                                    )
                                                },
                                                {
                                                    title: t('company:CONTACT'),
                                                    field: 'contact',
                                                },
                                                {
                                                    title: 'E-mail',
                                                    field: 'email',
                                                },
                                                {
                                                    title: t('company:PHONE'),
                                                    field: 'phone',
                                                },
                                                {
                                                    title: t('company:KVK_NUMBER'),
                                                    field: 'kvk_number',
                                                },
                                                {
                                                    title: t('company:BTW_NUMBER'),
                                                    field: 'btw_number',
                                                },
                                                {
                                                    title: 'ID',
                                                    field: 'parent_company',
                                                    hidden: false,
                                                    initialEditValue: rowData.id
                                                },
                                            ]}
                                            data={rowData.branch_offices}
                                            options={{
                                                emptyRowsWhenPaging: false,
                                                search: true,
                                            }}
                                            localization={localization(t)}
                                            editable={{
                                                onRowAdd: newData => new Promise((resolve, reject) => {
                                                    this.props.addBranchOffice(newData);
                                                    resolve();
                                                }),
                                                onRowDelete: oldData => new Promise((resolve, reject) => {
                                                    this.props.deleteBranchOffice(oldData);
                                                    resolve();
                                                }),
                                                onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                                                    const changed = deepCopy(oldData);
                                                    delete changed.tableData;
                                                    if (!isEqual(newData, changed)) {
                                                        this.props.editBranchOffice(newData, oldData);
                                                    }
                                                    resolve();
                                                }),
                                            }}
                                            detailPanel={[
                                                {
                                                    icon: AccountCircle,
                                                    tooltip: t('USERS'),
                                                    render: rowData => {
                                                        return (
                                                            <Container maxWidth='xl'>
                                                                <MaterialTable
                                                                    icons={tableIcons}
                                                                    title={t('USERS')}
                                                                    columns={[
                                                                        {
                                                                            title: t('USERNAME'),
                                                                            field: 'username',
                                                                            defaultSort: 'asc',
                                                                            editable: 'onAdd'
                                                                        },
                                                                        {
                                                                            title: t('ADMIN'),
                                                                            field: 'is_staff',
                                                                            type: 'boolean',
                                                                            initialEditValue: false
                                                                        },
                                                                        {
                                                                            title: t('IS_ACTIVE'),
                                                                            field: 'is_active',
                                                                            type: 'boolean',
                                                                            initialEditValue: false
                                                                        },
                                                                        {
                                                                            title: t('FIRST_NAME'),
                                                                            field: 'first_name',
                                                                            initialEditValue: ''
                                                                        },
                                                                        {
                                                                            title: t('LAST_NAME'),
                                                                            field: 'last_name',
                                                                            initialEditValue: ''
                                                                        },
                                                                        {
                                                                            title: 'E-mail',
                                                                            field: 'email',
                                                                            initialEditValue: ''
                                                                        },
                                                                        {
                                                                            title: t('PHONE'),
                                                                            field: 'phone_number',
                                                                            initialEditValue: ''
                                                                        },
                                                                        {
                                                                            title: t('DEPARTMENT'),
                                                                            field: 'department',
                                                                            initialEditValue: ''
                                                                        },
                                                                        {
                                                                            title: t('MANAGER'),
                                                                            field: 'manager',
                                                                            initialEditValue: ''
                                                                        },
                                                                        {
                                                                            title: t('ROLE'),
                                                                            field: 'user_role',
                                                                            initialEditValue: '',
                                                                            cellStyle: {whiteSpace: 'nowrap'},
                                                                            render: rowData => rowData.user_role,
                                                                            editComponent: props => (
                                                                                <SelectRole value={props.value}
                                                                                            onChange={props.onChange}
                                                                                            roles={this.props.user_roles.items}/>
                                                                            )
                                                                        },
                                                                        {
                                                                            title: t('SEARCH_ENGINES'),
                                                                            field: 'search_engines',
                                                                            render: rowData => rowData.search_engines.join(', '),
                                                                            editComponent: props => (
                                                                                <SelectEngine value={props.value}
                                                                                              onChange={props.onChange}
                                                                                              eSearch={this.props.esearch.items}/>
                                                                            ),
                                                                            initialEditValue: [],
                                                                            cellStyle: {whiteSpace: 'nowrap'}
                                                                        },
                                                                        {
                                                                            title: t('BRANCH_OFFICE'),
                                                                            field: 'branch_office',
                                                                            hidden: false,
                                                                            initialEditValue: rowData.id
                                                                        }
                                                                    ]}
                                                                    data={rowData.users}
                                                                    options={{
                                                                        emptyRowsWhenPaging: false,
                                                                        search: true,
                                                                    }}
                                                                    localization={localization(t)}
                                                                    editable={{
                                                                        onRowAdd: newData => new Promise((resolve, reject) => {
                                                                            this.props.registerUser(newData);
                                                                            resolve();
                                                                        }),
                                                                        onRowDelete: oldData => new Promise((resolve, reject) => {
                                                                            this.props.deleteUser(oldData);
                                                                            resolve();
                                                                        }),
                                                                        onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                                                                            const changed = deepCopy(oldData);
                                                                            delete changed.tableData;
                                                                            if (!isEqual(newData, changed)) {
                                                                                this.props.editUser(newData, oldData);
                                                                            }
                                                                            resolve();
                                                                        }),
                                                                    }}
                                                                />
                                                            </Container>
                                                        )
                                                    }
                                                }
                                            ]}
                                        />
                                    </Container>
                                )
                            }
                        },
                        {
                            icon: PieChart,
                            //openIcon: Settings,
                            tooltip: 'Cost Centers',
                            render: rowData => {
                                return (
                                    <Container maxWidth='lg'>
                                        <MaterialTable
                                            icons={tableIcons}
                                            title='Cost Centers'
                                            columns={[
                                                {
                                                    title: 'Name',
                                                    field: 'name',
                                                    defaultSort: 'asc'
                                                },
                                                {
                                                    title: 'Cost Code',
                                                    field: 'cost_code'
                                                },
                                                {
                                                    title: 'Company',
                                                    field: 'company',
                                                    hidden: false,
                                                    initialEditValue: rowData.id
                                                }
                                            ]}
                                            data={rowData.cost_centers}
                                            options={{
                                                emptyRowsWhenPaging: false,
                                                search: false,
                                            }}
                                            localization={localization(t)}
                                            editable={{
                                                onRowAdd: newData => new Promise((resolve, reject) => {
                                                    this.props.addCostCenter(newData);
                                                    resolve();
                                                }),
                                                onRowDelete: oldData => new Promise((resolve, reject) => {
                                                    this.props.deleteCostCenter(oldData);
                                                    resolve();
                                                }),
                                                onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                                                    const changed = deepCopy(oldData);
                                                    delete changed.tableData;
                                                    if (!isEqual(newData, changed)) {
                                                        this.props.editCostCenter(newData, oldData);
                                                    }
                                                    resolve();
                                                }),
                                            }}
                                        />
                                    </Container>
                                )
                            }
                        }
                    ]}
                />
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    companies: state.esearchReducer.companies,
    user_roles: state.esearchReducer.user_roles,
    company_roles: state.esearchReducer.company_roles,
    licenses: state.esearchReducer.licenses,
    esearch: state.esearchReducer.esearch,
    countries: state.esearchReducer.countries,
});

export default withStyles(useStyles)(connect(mapStateToProps, {
    getCompanies,
    addCompany,
    editCompany,
    deleteCompany,
    getAllCompanyRoles,
    getAllUserRoles,
    getAllLicenses,
    addBranchOffice,
    editBranchOffice,
    deleteBranchOffice,
    getAllEngines,
    registerUser,
    editUser,
    deleteUser,
    addCostCenter,
    editCostCenter,
    deleteCostCenter,
    getCountries
})(withTranslation(['common', 'company'])(Company)));