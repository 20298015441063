import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getUsers, editUser, deleteUser, registerUser} from "../../actions/users";
import {getAllEngines, getAllUserRoles} from "../../actions/esearch";
import SelectEngine from './SelectEngine';
import deepCopy from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {withStyles} from "@material-ui/core/styles";
import {Container} from '@material-ui/core';
import MaterialTable from 'material-table';
import {forwardRef} from 'react';
import {withTranslation} from 'react-i18next';
import localization from '../common/MaterialTableLocalization'

import {
    AddBox,
    ArrowUpward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    Remove,
    SaveAlt,
    Search,
    ViewColumn
} from "@material-ui/icons";
import SelectRole from "../esearch/SelectRole";
import {calculatePage} from "../../utils/tableUtils";
import {PAGE_SIZE} from "../../utils/constants";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

const useStyles = theme => {
    return ({
        paper: {
            marginTop: theme.spacing(3),
            width: '100%',
            overflowX: 'auto',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 650,
        },
        button: {
            margin: theme.spacing(1),
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        }
    })
};


class Users extends Component {
    static propTypes = {
        users: PropTypes.array.isRequired,
        getUsers: PropTypes.func.isRequired,
        deleteUser: PropTypes.func.isRequired,
        editUser: PropTypes.func.isRequired,
        registerUser: PropTypes.func.isRequired,
        getAllEngines: PropTypes.func.isRequired,
        esearch: PropTypes.array.isRequired,
        user_roles: PropTypes.array.isRequired,
        getAllUserRoles: PropTypes.func.isRequired
    };

    state = {
        page: 0,
        pageSize: PAGE_SIZE
    }

    handlePageChange = (newPage) => {
        this.setState({ page: newPage }, () => {
            this.fetchUsers();
        });
    }

    handlePageSizeChange = (newPageSize) => {
        this.setState({ pageSize: newPageSize, page: 0 }, () => {
            this.fetchUsers();
        });
    }

    fetchUsers = () => {
        const { page, pageSize } = this.state;
        this.props.getUsers(page + 1, pageSize);
    }

    handleRowDelete = (supplierId) => {
        this.props.deleteUser(supplierId);
        const {page, pageSize} = this.state;
        const newPage = calculatePage(page, pageSize, this.props.users.totalCount)
        this.handlePageChange(newPage)
    }

    componentDidMount() {
        this.fetchUsers();
        this.props.getAllEngines(1, 1000);
        this.props.getAllUserRoles(1, 1000);
    }



    render() {
        const {classes, t} = this.props;
        return (
            <Container>
                <MaterialTable
                    icons={tableIcons}
                    title="Users"
                    columns={[
                        {title: t('USERNAME'), field: 'username', defaultSort: 'asc', editable: 'onAdd'},
                        {title: t('ADMIN'), field: 'is_staff', type: 'boolean', initialEditValue: false},
                        {title: t('IS_ACTIVE'), field: 'is_active', type: 'boolean', initialEditValue: false},
                        {title: t('FIRST_NAME'), field: 'first_name', initialEditValue: ''},
                        {title: t('LAST_NAME'), field: 'last_name', initialEditValue: ''},
                        {title: 'E-mail', field: 'email', initialEditValue: ''},
                        {title: t('PHONE'), field: 'phone_number', initialEditValue: ''},
                        {title: t('DEPARTMENT'), field: 'department', initialEditValue: ''},
                        {title: t('MANAGER'), field: 'manager', initialEditValue: ''},
                        {
                            title: t('ROLE'),
                            field: 'user_role',
                            initialEditValue: '',
                            cellStyle: {whiteSpace: 'nowrap'},
                            render: rowData => rowData.user_role,
                            editComponent: props => (
                                <SelectRole value={props.value}
                                            onChange={props.onChange}
                                            roles={this.props.user_roles.items}/>
                            )
                        },
                        {
                            title: t('SEARCH_ENGINES'), field: 'search_engines',
                            render: rowData => rowData.search_engines.join(', '),
                            editComponent: props => (
                                <SelectEngine value={props.value} onChange={props.onChange}
                                              eSearch={this.props.esearch.items}/>
                            ),
                            initialEditValue: [],
                            cellStyle: {whiteSpace: 'nowrap'}
                        },
                        {
                            title: t('BRANCH_OFFICE'),
                            field: 'branch_office',
                            hidden: false,
                            initialEditValue: ''}
                    ]}
                    data={this.props.users.items}
                    page={this.state.page}
                    totalCount={this.props.users.totalCount}
                    options={{
                        sorting: true,
                        pageSize: this.state.pageSize,
                    }}
                    onChangePage={(newPage) => this.handlePageChange(newPage)}
                    onChangeRowsPerPage={(newPageSize) => this.handlePageSizeChange(newPageSize)}
                    editable={{
                        // onRowAdd: newData => new Promise((resolve, reject) => {
                        //     this.props.registerUser(newData);
                        //     resolve();
                        // }),
                        onRowDelete: rowData => new Promise((resolve, reject) => {
                            this.handleRowDelete(rowData.id);
                            resolve();
                        }),
                        onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                            const changed = deepCopy(oldData);
                            delete changed.tableData;
                            if (!isEqual(newData, changed)) {
                                this.props.editUser(newData, oldData);
                            }
                            resolve();
                        }),
                    }}
                    localization={localization(t)}
                />
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    users: state.usersReducer.users,
    esearch: state.esearchReducer.esearch,
    user_roles: state.esearchReducer.user_roles,
});

export default withStyles(useStyles)(connect(mapStateToProps, {
    getUsers,
    deleteUser,
    editUser,
    registerUser,
    getAllEngines,
    getAllUserRoles
})(withTranslation(['common'])(Users)));