import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import deepCopy from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {withStyles} from "@material-ui/core/styles";
import {Container, IconButton, Tooltip} from '@material-ui/core';
import MaterialTable from 'material-table';
import {forwardRef} from 'react';
import {withTranslation} from "react-i18next";
import localization from '../../common/MaterialTableLocalization';

import {
    AddBox,
    ArrowUpward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    Remove,
    SaveAlt,
    Search,
    ViewColumn,
    ImageSearch
} from "@material-ui/icons";
import {
    getCountries
} from "../../../actions/esearch";
import SelectCountry from "../SelectCountry";
import SupplierCostTable from "./SupplierCostTable";
import {
    addSupplier2ba,
    deleteSupplier2ba,
    editSupplier2ba,
    getSuppliers2ba,
    uploadSupplierLogo
} from "../../../actions/supplier";
import {calculatePage} from "../../../utils/tableUtils";
import {PAGE_SIZE} from "../../../utils/constants";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

const useStyles = theme => {
    return ({
        paper: {
            marginTop: theme.spacing(3),
            width: '100%',
            overflowX: 'auto',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 650,
        },
        button: {
            margin: theme.spacing(1),
        }
    })
};

class Supplier2ba extends Component {
    static propTypes = {
        suppliers2ba: PropTypes.array.isRequired,
        getSuppliers2ba: PropTypes.func.isRequired,
        addSupplier2ba: PropTypes.func.isRequired,
        editSupplier2ba: PropTypes.func.isRequired,
        deleteSupplier2ba: PropTypes.func.isRequired,
        getCountries: PropTypes.func.isRequired,
        countries: PropTypes.array.isRequired,
        fallbackImage: PropTypes.string.isRequired,
        uploadSupplierLogo: PropTypes.func.isRequired,
        user: PropTypes.func.isRequired
    };

    state = {
        page: 0,
        pageSize: PAGE_SIZE,
        searchQuery: ''
    }

    handlePageChange = (newPage) => {
        this.setState({page: newPage}, () => {
            this.fetchSuppliers();
        });
    }

    handlePageSizeChange = (newPageSize) => {
        this.setState({pageSize: newPageSize, page: 0}, () => {
            this.fetchSuppliers();
        });
    }

    handleSearchChange = (searchQuery) => {
        this.setState({searchQuery, page: 0}, () => {
            this.fetchSuppliers();
        });
    }

    fetchSuppliers = () => {
        const {page, pageSize, searchQuery} = this.state;
        this.props.getSuppliers2ba(page + 1, pageSize, searchQuery);
    }

    handleRowDelete = (supplierId) => {
        this.props.deleteSupplier2ba(supplierId);
        const {page, pageSize} = this.state;
        const newPage = calculatePage(page, pageSize, this.props.suppliers2ba.totalCount)
        this.handlePageChange(newPage)
    }

    componentDidMount() {
        this.fetchSuppliers();
        this.props.getCountries();
    };

    chosenImageRef = React.createRef();

    render() {
        const {classes, t, fallbackImage, user} = this.props;

        const replaceBrokenImage = event => {
            event.target.src = fallbackImage
        };

        const showDialog = () => {
            this.chosenImageRef.current.click();
        };

        const submitFile = (rowData) => {
            if (this.chosenImageRef.current.files.length === 1) {
                const data = new FormData();
                data.append('file', this.chosenImageRef.current.files[0]);
                data.append('rowData', JSON.stringify(rowData));
                this.props.uploadSupplierLogo(data);
            }
        };

        return (
            <Container maxWidth={false}>
                <MaterialTable
                    icons={tableIcons}
                    title={t('company:SUPPLIERS')}
                    columns={[
                        {
                            title: t('company:IMAGE'),
                            field: 'image_url',
                            editable: 'always',
                            editComponent: (row) => (
                                <div>
                                    <input
                                        accept='image/*'
                                        style={{display: 'none'}}
                                        type='file'
                                        ref={this.chosenImageRef}
                                        onChange={() => submitFile(row.rowData)}
                                    />
                                    <Tooltip title='Upload Logo'>
                                        <IconButton onClick={showDialog}>
                                            <ImageSearch/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            ),
                            render: rowData => <img
                                src={rowData.image_url}
                                style={{width: 40, borderRadius: '50%'}}
                                onError={replaceBrokenImage}
                            />
                        },
                        {title: t('company:NAME'), field: 'company_name', defaultSort: 'asc'},
                        {title: 'GLN', field: 'gln'},
                        {title: t('company:COMPANY_ID'), field: 'company_id'},
                        {title: t('company:ADDRESS'), field: 'address'},
                        {title: t('company:BUILDING_NUMBER'), field: 'building_number'},
                        {title: t('company:CITY'), field: 'city'},
                        {title: t('company:POST_CODE'), field: 'postcode'},
                        {
                            title: t('company:COUNTRY'), field: 'country',
                            initialEditValue: 'NL',
                            cellStyle: {whiteSpace: 'nowrap'},
                            render: rowData => rowData.country,
                            editComponent: props => (
                                <SelectCountry value={props.value} onChange={props.onChange}
                                               countries={this.props.countries}/>
                            )
                        },
                        {title: t('E-mail'), field: 'email'},
                        {title: t('company:PHONE'), field: 'phone'},
                        {title: t('company:ENABLED'), field: 'is_enabled', type: 'boolean'},
                    ]}
                    options={{
                        sorting: true,
                        rowStyle: {
                            backgroundColor: 'palette.text.disabled'
                        },
                        headerStyle: {
                            backgroundColor: 'palette.text.disabled'
                        },
                        pageSize: this.state.pageSize,
                    }}
                    data={this.props.suppliers2ba.items}
                    page={this.state.page}
                    totalCount={this.props.suppliers2ba.totalCount}
                    onChangePage={this.handlePageChange}
                    onChangeRowsPerPage={this.handlePageSizeChange}
                    onSearchChange={this.handleSearchChange}
                    editable={{
                        onRowAdd: newData => new Promise((resolve, reject) => {
                            this.props.addSupplier2ba(newData);
                            resolve();
                        }),
                        onRowDelete: rowData => new Promise((resolve, reject) => {
                            this.handleRowDelete(rowData.id)
                            resolve();
                        }),
                        onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                            const changed = deepCopy(oldData);
                            delete changed.tableData;
                            if (!isEqual(newData, changed)) {
                                this.props.editSupplier2ba(newData, oldData);
                            }
                            resolve();
                        }),
                    }}
                    localization={localization(t)}
                    onRowClick={((event, rowData, togglePanel) => {
                            event.preventDefault();
                            if (user.is_esearch_admin) {
                                togglePanel();
                            }
                        }
                    )}
                    detailPanel={
                        user.is_esearch_admin ?
                            [
                                {
                                    tooltip: t('company:SUPPLIER_COSTS'),
                                    disabled: true,
                                    render: rowData => {
                                        return (
                                            <Container style={{maxWidth: '90%'}}>
                                                <SupplierCostTable
                                                    supplier={rowData}
                                                    t={t}
                                                    tableIcons={tableIcons}
                                                />
                                            </Container>
                                        )
                                    }
                                }
                            ]
                            : null
                    }
                />
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    suppliers2ba: state.supplierReducer.suppliers2ba,
    fallbackImage: state.authReducer.fallbackImage,
    countries: state.esearchReducer.countries,
    user: state.authReducer.user,
});

export default withStyles(useStyles)(connect(mapStateToProps, {
    getSuppliers2ba,
    addSupplier2ba,
    editSupplier2ba,
    deleteSupplier2ba,
    getCountries,
    uploadSupplierLogo
})(withTranslation(['common', 'company'])(Supplier2ba)));