import {
    GET_ENGINES,
    ADD_ENGINE,
    EDIT_ENGINE,
    GET_OCI,
    GET_COMPANIES,
    GET_OCI_ELEMENTS,
    GET_SEARCH_ELEMENTS,
    EDIT_ELEMENTS_MAPPING,
    DELETE_ELEMENTS_MAPPING,
    ADD_ELEMENTS_MAPPING,
    EDIT_SEARCH_PARAMS,
    DELETE_SEARCH_PARAMS,
    ADD_SEARCH_PARAMS,
    EDIT_COMPANY,
    ADD_COMPANY,
    GET_COMPANY_ROLES,
    ADD_COMPANY_ROLE,
    EDIT_COMPANY_ROLE,
    GET_USER_ROLES,
    ADD_USER_ROLE,
    EDIT_USER_ROLE,
    GET_LICENSES,
    ADD_LICENSE,
    EDIT_LICENSE,
    EXECUTE_SEARCH,
    SEARCHING,
    CLEAR_SEARCH,
    TRADE_ITEMS_2BA,
    GET_COUNTRIES,
    WEBSOCKET_TRADE_ITEMS,
    DIRECT_SEARCH,
    CLEAR_DIRECT_SEARCH,
    ADD_BACKGROUND_SEARCH,
    OCI_SEARCH,
    CLEAR_ESEARCH,
    GET_ALL_USER_ROLES,
    GET_ALL_COMPANY_ROLES,
    GET_ALL_LICENSES,
    GET_ALL_ENGINES,
    GET_ALL_COMPANIES,
    GET_ALL_OCI_ELEMENTS,
    GET_ALL_OCI,
    GET_ALL_SEARCH_ELEMENTS
} from '../actions/types'


const initialState = {
    esearch: {
        items: [],
        totalCount: 0
    },
    oci: [],
    companies: {
        items: [],
        totalCount: 0
    },
    oci_elements: {
        items: [],
        totalCount: 0
    },
    search_elements: {
        items: [],
        totalCount: 0
    },
    elements_mapping: {
        items: [],
        totalCount: 0
    },
    company_roles: {
        items: [],
        totalCount: 0
    },
    user_roles: {
        items: [],
        totalCount: 0
    },
    licenses: {
        items: [],
        totalCount: 0
    },
    search_results_2ba: {
        Products: [],
        TotalFound: 0
    },
    search_results_oci: {
        Products: [],
        TotalFound: 0
    },
    searching: false,
    search_string: '',
    countries: [],
    direct_search: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_ESEARCH:
            return initialState
        case GET_ENGINES:
            return {
                ...state,
                esearch: {
                    items: action.payload.results,
                    totalCount: action.payload.count
                }
            };
        case GET_ALL_ENGINES:
            return {
                ...state,
                esearch: {
                    items: action.payload,
                    totalCount: action.payload.length
                }
            };
        case ADD_ENGINE:
            return {
                ...state,
                esearch: {
                    items: [...state.esearch.items, action.payload],
                    totalCount: state.esearch.totalCount + 1
                }
            };
        case EDIT_ENGINE:
            return {
                ...state,
                esearch: {
                    ...state.esearch,
                    items: state.esearch.items.filter(es => es !== action.oldData).concat(action.newData)
                }
            };
        case GET_OCI:
            return {
                ...state,
                oci: {
                    items: action.payload.results,
                    totalCount: action.payload.count
                }
            };
        case GET_ALL_OCI:
            return {
                ...state,
                oci: {
                    items: action.payload,
                    totalCount: action.payload.length
                }
            };
        case GET_COMPANIES:
            return {
                ...state,
                companies: {
                    items: action.payload.results,
                    totalCount: action.payload.count
                }
            };
        case GET_ALL_COMPANIES:
            return {
                ...state,
                companies: {
                    items: action.payload,
                    totalCount: action.payload.length
                }
            };
        case EDIT_COMPANY:
            return {
                ...state,
                companies: {
                    ...state.companies,
                    items: state.companies.items.filter(company => company !== action.oldData).concat(action.newData)
                }
            };
        case ADD_COMPANY:
            return {
                ...state,
                companies: {
                    items: [...state.companies.items, action.payload],
                    totalCount: state.companies.totalCount + 1
                }
            };
        case GET_OCI_ELEMENTS:
            return {
                ...state,
                oci_elements: {
                    items: action.payload.results,
                    totalCount: action.payload.count
                }
            };
        case GET_ALL_OCI_ELEMENTS:
            return {
                ...state,
                oci_elements: {
                    items: action.payload,
                    totalCount: action.payload.length
                }
            };
        case GET_SEARCH_ELEMENTS:
            return {
                ...state,
                search_elements: {
                    items: action.payload.results,
                    totalCount: action.payload.count
                }
            };
        case GET_ALL_SEARCH_ELEMENTS:
            return {
                ...state,
                search_elements: {
                    items: action.payload,
                    totalCount: action.payload.length
                }
            };
        case EDIT_ELEMENTS_MAPPING:
            return {
                ...state,

                // TODO: get state updated for esearch child elements Issue #9
                // esearch: state.esearch.map(function(es){
                //     es.elements_mapping.filter(em => em.id !== action.oldData).concat(action.newData);
                // })
            };
        case DELETE_ELEMENTS_MAPPING:
            return {
                ...state,
                //TODO: same as above
            };
        case ADD_ELEMENTS_MAPPING:
            return {
                ...state,
            };
        case EDIT_SEARCH_PARAMS:
            return {
                ...state,

                // TODO: get state updated for esearch child elements Issue #9
            };
        case DELETE_SEARCH_PARAMS:
            return {
                ...state,
                //TODO: same as above
            };
        case ADD_SEARCH_PARAMS:
            //TODO: same as above
            return {
                ...state,
            };
        case GET_COMPANY_ROLES:
            return {
                ...state,
                company_roles: {
                    items: action.payload.results,
                    totalCount: action.payload.count
                }
            };
        case GET_ALL_COMPANY_ROLES:
            return {
                ...state,
                company_roles: {
                    items: action.payload,
                    totalCount: action.payload.length
                }
            };
        case ADD_COMPANY_ROLE:
            return {
                ...state,
                company_roles: {
                    items: [...state.company_roles.items, action.payload],
                    totalCount: state.company_roles.totalCount + 1
                }
            };
        case EDIT_COMPANY_ROLE:
            return {
                ...state,
                company_roles: {
                    ...state.company_roles,
                    items: state.company_roles.items.filter(role => role !== action.oldData).concat(action.newData)
                }
            };
        case GET_USER_ROLES:
            return {
                ...state,
                user_roles: {
                    items: action.payload.results,
                    totalCount: action.payload.count
                }
            };
        case GET_ALL_USER_ROLES:
            return {
                ...state,
                user_roles: {
                    items: action.payload,
                    totalCount: action.payload.length
                }
            };
        case ADD_USER_ROLE:
            return {
                ...state,
                user_roles: {
                    items: [...state.user_roles.items, action.payload],
                    totalCount: state.user_roles.totalCount + 1
                }
            };
        case EDIT_USER_ROLE:
            return {
                ...state,
                user_roles: {
                    ...state.user_roles,
                    items: state.user_roles.items.filter(role => role !== action.oldData).concat(action.newData),
                }
            };
        case GET_LICENSES:
            return {
                ...state,
                licenses: {
                    items: action.payload.results,
                    totalCount: action.payload.count
                }
            };
        case GET_ALL_LICENSES:
            return {
                ...state,
                licenses: {
                    items: action.payload,
                    totalCount: action.payload.length
                }
            };
        case ADD_LICENSE:
            return {
                ...state,
                licenses: {
                    items: [...state.licenses.items, action.payload],
                    totalCount: state.licenses.totalCount + 1
                }
            };
        case EDIT_LICENSE:
            return {
                ...state,
                licenses: {
                    ...state.licenses,
                    items: state.licenses.items.filter(lic => lic !== action.oldData).concat(action.newData)
                }
            };
        case EXECUTE_SEARCH:
            return {
                ...state,
                search_results_2ba: {
                    Products: action.payload.Products.map(item => item === item ? {...item, TradeItems: []} : item),
                    TotalFound: action.payload.TotalFound
                },
                searching: false
            };
        case OCI_SEARCH:
            return {
                ...state,
                search_results_oci: action.payload,
                searching: false
            }
        case SEARCHING:
            return {
                ...state,
                searching: true,
                search_string: action.payload
            };
        case CLEAR_SEARCH:
            return {
                ...state,
                searching: false,
                search_string: '',
                search_results_2ba: {
                    Products: [],
                    TotalFound: 0
                },
                search_results_oci: {
                    Products: [],
                    TotalFound: 0
                }
            };
        case TRADE_ITEMS_2BA:
            return {
                ...state,
                search_results_2ba: {
                    Products: state.search_results_2ba.Products.map(item => item.Id !== action.id ? item : {
                        ...item,
                        TradeItems: action.payload,
                        BackgroundSearch: []
                    }), TotalFound: state.search_results_2ba.TotalFound
                }
            };
        case GET_COUNTRIES:
            return {
                ...state,
                countries: action.payload
            };
        case WEBSOCKET_TRADE_ITEMS:
            return {
                ...state,
                search_results_2ba: {
                    Products: state.search_results_2ba.Products.map(item => item.Id !== parseInt(action.id) ? item :
                        Object.assign({}, item, {TradeItems: action.payload.concat(item.BackgroundSearch)})
                    ), TotalFound: state.search_results_2ba.TotalFound
                }
            };
        case ADD_BACKGROUND_SEARCH:
            return {
                ...state,
                search_results_2ba: {
                    Products: state.search_results_2ba.Products.map(item => item.Id !== parseInt(action.id) ? item :
                        Object.assign({}, item, {
                            TradeItems: item.TradeItems.concat(action.payload),
                            BackgroundSearch: item.BackgroundSearch.concat(action.payload)
                        })
                    ), TotalFound: state.search_results_2ba.TotalFound
                }
            };
        case DIRECT_SEARCH:
            return {
                ...state,
                direct_search: true,
                searching: true,
                search_string: action.payload
            };
        case CLEAR_DIRECT_SEARCH:
            return {
                ...state,
                direct_search: false,
            };
        default:
            return state;
    }
}